import api from "@/core/services/api.service";

export default {
  get PATH() {
    return "secondment-actors";
  },

  get ActorKinds() {
    return {
      legal_person: "Company",
      physical_person: "Physical person",
      administrative_structure: "Administrative structures",
    };
  },

  // kind, options are: legal_person, physical_person, administrative_structure
  async getAll({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    countryAddress = null,
    kind = null,
    inGroup = null,
    isClientOnSecondments = false,
    isContractorOnSecondments = false,
    isPartnerOnSecondments = false,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      country_address: countryAddress,
      kind: kind,
      in_group: inGroup,
      clients: isClientOnSecondments,
      contractors: isContractorOnSecondments,
      partners: isPartnerOnSecondments,
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    res.data.results = res.data.results.map((actor) => {
      let element = {
        ...actor,
      };

      if (element.kind == "Company") {
        element.companyaddress_set = element.addresses;
      } else {
        element.physicalpersonaddress_set = element.addresses;
      }
      return element;
    });

    return res.data;
  },
};
