var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('datatable', {
    ref: "table",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "filter": _vm.tableFilters,
      "tr-class": _vm.trClasses
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "topbar",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "col 16",
          class: {
            'px-4': _vm.title
          }
        }, [_c('euro-select', {
          attrs: {
            "multiselect-props": _vm.countryMultiselectProps
          },
          model: {
            value: _vm.countryFilter,
            callback: function callback($$v) {
              _vm.countryFilter = $$v;
            },
            expression: "countryFilter"
          }
        })], 1), _c('div', {
          staticClass: "col 16"
        }, [_c('euro-select', {
          attrs: {
            "multiselect-props": _vm.actorTypeMultiselectProps
          },
          model: {
            value: _vm.actorTypeFilter,
            callback: function callback($$v) {
              _vm.actorTypeFilter = $$v;
            },
            expression: "actorTypeFilter"
          }
        })], 1), _c('div', {
          staticClass: "col 16"
        }, [_c('euro-input', {
          staticClass: "w-100",
          attrs: {
            "placeholder": _vm.$t('Search'),
            "debounce": 300
          },
          model: {
            value: _vm.searchFilter,
            callback: function callback($$v) {
              _vm.searchFilter = $$v;
            },
            expression: "searchFilter"
          }
        }, [_c('b-input-group-prepend', [_c('span', {
          staticClass: "input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"
        }, [_c('i', {
          class: "".concat(_vm.icons.search)
        })])])], 1)], 1), _vm.title === 'Supplier' ? _c('euro-checkbox', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-6 mt-3",
          attrs: {
            "title": _vm.$t('Filter only entities tagged as suppliers'),
            "text": _vm.$t('Suppliers'),
            "size": "lg",
            "is-switch": ""
          },
          on: {
            "input": function input($event) {
              return _vm.$refs.table.refresh();
            }
          },
          model: {
            value: _vm.isSuppliers,
            callback: function callback($$v) {
              _vm.isSuppliers = $$v;
            },
            expression: "isSuppliers"
          }
        }) : _vm._e(), _vm.title === 'Contractor' ? _c('euro-checkbox', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-6 mt-3",
          attrs: {
            "title": _vm.$t('Filter only entities tagged as contractors'),
            "text": _vm.$t('Contractors'),
            "size": "lg",
            "is-switch": ""
          },
          on: {
            "input": function input($event) {
              return _vm.$refs.table.refresh();
            }
          },
          model: {
            value: _vm.isContractor,
            callback: function callback($$v) {
              _vm.isContractor = $$v;
            },
            expression: "isContractor"
          }
        }) : _vm._e(), _vm.$slots['topbar-end'] ? _c('div', [_vm._t("topbar-end")], 2) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "header._selected",
      fn: function fn() {
        return [_c('div')];
      },
      proxy: true
    }, {
      key: "header.companyfiscalid_set",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("FISCAL ID")) + " ")];
      },
      proxy: true
    }, {
      key: "cell._selected",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "size": "lg",
            "checked": _vm.isSelected(item)
          },
          on: {
            "change": function change($event) {
              return _vm.onSelecteCheckboxChange(item);
            }
          }
        })];
      }
    }, {
      key: "cell.countries",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.countries && item.countries.length ? _vm._l(item.countries, function (country) {
          return _c('country-flag', {
            key: country,
            attrs: {
              "country-iso": country
            }
          });
        }) : _c('span')];
      }
    }, {
      key: "cell.vat",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bolder h-100 text-uppercase",
          class: {
            'text-danger': _vm.getActorVat(item).isExpired
          }
        }, [_vm._v(" " + _vm._s(_vm.getActorVat(item).vat) + " ")])];
      }
    }, {
      key: "cell.fiscal_id",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "font-weight-bolder h-100 text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.fiscalID(item).fiscalId) + " ")])];
      }
    }, {
      key: "cell.name",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('avatar', {
          attrs: {
            "avatar-image": item.logo,
            "avatar-text": item.name[0]
          }
        }), _c('div', {
          staticClass: "ml-3 font-weight-bolder"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }, {
      key: "cell.intragroup",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "size": "lg"
          },
          model: {
            value: item.intragroup,
            callback: function callback($$v) {
              _vm.$set(item, "intragroup", $$v);
            },
            expression: "item.intragroup"
          }
        })];
      }
    }, _vm._l(_vm.customColumns, function (customCol) {
      return {
        key: "cell.".concat(customCol.key),
        fn: function fn(_ref7) {
          var item = _ref7.item;
          return [_vm._t("cell.".concat(customCol.key), null, {
            "item": item
          })];
        }
      };
    })], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }