<template>
  <div>
    <datatable ref="table" :table-props="tableProps" :total="total" :fields="fields" :options.sync="tableOptions"
      :per-page-options="perPageOptions" :filter="tableFilters" :tr-class="trClasses">
      <template #topbar>
        <div class="d-flex justify-content-between">

          <div class="col 16" :class="{ 'px-4': title }">
            <euro-select v-model="countryFilter" :multiselect-props="countryMultiselectProps"></euro-select>
          </div>
          <div class="col 16">
            <euro-select v-model="actorTypeFilter" :multiselect-props="actorTypeMultiselectProps"></euro-select>
          </div>
          <div class="col 16">
            <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="w-100" :debounce="300">
              <b-input-group-prepend>
                <span
                  class="input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"><i
                    :class="`${icons.search}`"></i></span>
              </b-input-group-prepend>
            </euro-input>
          </div>
          <euro-checkbox v-if="title === 'Supplier'" v-model="isSuppliers" v-b-tooltip.hover :title="$t('Filter only entities tagged as suppliers')" :text="$t('Suppliers')" size="lg" is-switch
            class="mr-6 mt-3" @input="$refs.table.refresh()"></euro-checkbox>
          <euro-checkbox v-if="title === 'Contractor'" v-model="isContractor" v-b-tooltip.hover :title="$t('Filter only entities tagged as contractors')" :text="$t('Contractors')" size="lg" is-switch
            class="mr-6 mt-3" @input="$refs.table.refresh()"></euro-checkbox>
          <div v-if="$slots['topbar-end']">
            <slot name="topbar-end"></slot>
          </div>
        </div>
      </template>
      <template #[`header._selected`]>
        <div></div>
      </template>
      <template #[`header.companyfiscalid_set`]>
        {{ $t("FISCAL ID") }}
      </template>
      <template #[`cell._selected`]="{ item }">
        <b-form-checkbox size="lg" :checked="isSelected(item)" @change="onSelecteCheckboxChange(item)"></b-form-checkbox>
      </template>
      <template #[`cell.countries`]="{ item }">
        <template v-if="item.countries && item.countries.length">
          <country-flag v-for="country in item.countries" :key="country" :country-iso="country"></country-flag>
        </template>
        <span v-else></span>
      </template>
      <template #[`cell.vat`]="{ item }">
        <div class="font-weight-bolder h-100 text-uppercase" :class="{
          'text-danger': getActorVat(item).isExpired,
        }">
          {{ getActorVat(item).vat }}
        </div>
      </template>
      <template #[`cell.fiscal_id`]="{ item }">
        <div class="font-weight-bolder h-100 text-uppercase">
          {{ fiscalID(item).fiscalId }}
        </div>
      </template>
      <template #[`cell.name`]="{ item }">
        <div class="d-flex align-items-center">
          <avatar :avatar-image="item.logo" :avatar-text="item.name[0]"></avatar>
          <div class="ml-3 font-weight-bolder">{{ item.name }}</div>
        </div>
      </template>
      <template #[`cell.intragroup`]="{ item }">
        <b-form-checkbox v-model="item.intragroup" switch size="lg"></b-form-checkbox>
      </template>

      <template v-for="customCol in customColumns" v-slot:[`cell.${customCol.key}`]="{ item }">
        <slot :name="`cell.${customCol.key}`" :item="item"> </slot>
      </template>
    </datatable>
  </div>
</template>

<script>
import SecondmentActorsService from "@/core/services/secondment/secondment-actors.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import icons from "@/core/config/icons.js";
import CountryFlag from "@/view/components/CountryFlag.vue";
import _ from "lodash";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    Datatable,
    CountryFlag,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
      validator: prop => typeof prop === "object" || prop === null,
    },
    defaultCountryFilter: {
      type: String,
      default: null,
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    actorId: {
      type: String,
      default: "id",
    },
    actors: {
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      icons,
      isLoading: false,
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      countryFilter: "",
      actorTypeFilter: "",
      initialActors: this.actors,
      isContractor: true,
      isSuppliers: true,
    };
  },

  computed: {
    actorTypeMultiselectProps() {
      const options = Object.keys(SecondmentActorsService.ActorKinds).map(k => ({
        text: SecondmentActorsService.ActorKinds[k],
        value: k,
      }));
      return {
        options: options,
        "track-by": "value",
        label: "text",
        multiple: false,
        "allow-empty": true,
        placeholder: this.$t("Type"),
      };
    },
    countryMultiselectProps() {
      const options = this.countries.map(el => ({ text: el.name, value: el.iso }));
      return {
        options: options,
        "track-by": "value",
        label: "text",
        multiple: false,
        "allow-empty": true,
        placeholder: this.$t("Country"),
        searchable: true,
      };
    },
    tableFilters() {
      return [this.searchFilter, this.countryFilter, this.actorTypeFilter];
    },
    fields() {
      let f = [
        { key: "_selected", sortable: false, class: "align-middle" },
        { label: this.$t("Name"), key: "name", sortable: true, class: "align-middle" },
        {
          label: this.$t("Countries"),
          key: "countries",
          sortable: false,
          class: "align-middle",
        },
        { label: this.$t("Vat"), key: "vat", sortable: false, class: "align-middle" },
        {
          label: this.$t("Fiscal ID"),
          key: "fiscal_id",
          sortable: false,
          class: "align-middle",
        },
      ];

      if (this.customColumns.length) {
        f = [...f, ...this.customColumns];
      }

      return f;
    },
    tableProps() {
      return {
        items: this.loadAssignments,
        fields: this.fields,
      };
    },

    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    selectedActor: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    defaultCountryFilter: {
      immediate: true,
      handler(val) {
        this.countryFilter = val;

        //title: props for distinguish the creation wizard steps
        if (this.title == "Supplier") {
          this.isContractor = false
          this.isSuppliers = true
        }
        if (this.title == "Contractor") {
          this.isContractor = true
          this.isSuppliers = false
        }
      },
    },
    searchFilter() {
      if (this.isContractor) {
        this.isContractor = false;
      }
      if (this.isSuppliers) {
        this.isSuppliers = false;
      }
    }
  },

  methods: {
    loadAssignments(ctx, callback) {
      SecondmentActorsService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        search: this.searchFilter,
        sortBy: ctx.sortBy || "name",
        sortDesc: ctx.sortDesc,
        countryAddress: this.countryFilter,
        kind: this.actorTypeFilter,
        isContractorOnSecondments: this.isContractor,
        isPartnerOnSecondments: this.isSuppliers,
      })
        .then(res => {
          this.total = res.count;

          let elements = res.results;

          callback(elements);
        })
        .catch(() => callback([]));

      return null;
    },

    fiscalID(actor) {
      const out = {
        fiscalId: "",
        isExpired: false,
      };
      if (!actor.fiscal_id || !actor.fiscal_id.length) return out;

      const fiscalId = actor.fiscal_id.find(v => v.is_active == true);

      if (!fiscalId) {
        out.fiscalId = this.$t("expired");
        out.isExpired = true;
        return out;
      };

      out.fiscalId = fiscalId.fiscalid;

      return out;
    },

    refresh() {
      this.$refs.table.refresh();
    },

    onSelecteCheckboxChange(actor) {
      if (this.multiSelect) {
        if (this.isSelected(actor)) {
          const filteredActors = this.selectedActor.filter(
            el => !(el[this.actorId] == actor.id && el.kind == actor.kind),
          );
          this.selectedActor = filteredActors;
        } else {
          this.selectedActor = [...this.selectedActor, actor];
        }

        return;
      }

      if (this.isSelected(actor)) {
        this.selectedActor = null;
        return;
      }

      this.selectedActor = actor;
    },

    isSelected(actor) {
      if (this.multiSelect) {
        const value = _.find(this.selectedActor, { [this.actorId]: actor.id, kind: actor.kind });
        return value ? true : false;
      }

      if (!this.selectedActor) return false;
      return this.selectedActor[this.actorId] === actor.id && this.selectedActor.kind == actor.kind;
    },

    trClasses(actor) {
      if (!actor) return;
      return {
        "table-success": this.isSelected(actor),
      };
    },

    onNotifyCheckboxInput(notify, actor) {
      this.selectedActor = { ...actor, notify };
    },

    getActorVat(actor) {
      const out = {
        vat: "",
        isExpired: false,
      };
      if (!actor.vat || !actor.vat.length) return out;

      const vat = actor.vat.find(v => v.is_active == true);

      if (!vat) {
        out.vat = this.$t("expired");
        out.isExpired = true
        return out
      };

      out.vat = vat.vat;

      return out;
    },
  },
};
</script>
