<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Contractor") }}</h3>
      </div>
    </div>
    <div class="card-body">
      <b-container v-if="loading">
        {{ $t("loading...") }}
      </b-container>
      <template v-else>
        <b-container v-if="contractor">
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="12" class="font-weight-bold d-flex justify-content-center">
                  <div class="d-flex flex-column align-items-center pb-5">
                    <avatar :avatar-image="contractor.avatar" :avatar-text="contractor.name[0]" size="100px"></avatar>
                    <span class="h2 text-capitalize">{{ contractor.name }}</span>
                  </div>
                </b-col>
                <b-col cols="12" class="font-weight-bold d-flex align-items-center pb-5">
                  <span class="pr-3 form-label"> {{ $t("Allow notifications") }}:</span>
                  <b-form-checkbox v-model="contractor.notify" disabled switch size="lg"></b-form-checkbox>
                </b-col>
                <div v-if="contractor.vat">
                  <b-row class="font-weight-bold d-flex align-items-center pb-5 ml-5">
                    <div class="pr-3 pb-2 form-label">{{ $t("Vat") }}:</div>
                    <b-col v-for="[country, vat] in Object.entries(contractor.vat)" :key="vat" cols="12 pb-2">
                      <CountryFlag :country-iso="country" class="px-1"></CountryFlag>
                      {{ vat }}
                    </b-col>
                  </b-row>
                </div>
                <b-col cols="12" class="font-weight-bold d-flex align-items-baseline pb-5">
                  <span class="pr-3 form-label">{{ $t("Address") }}:</span>
                  <p>{{ contractorAddressRaw }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="font-weight-bold d-flex align-items-baseline pb-5">
              <span v-if="contractor.registered_office">
                <span class="pr-3 form-label">{{ $t("Registered office") }}:</span>
                <span v-for="office in contractor.registered_office" :key="office.address.id">
                  {{ office.address.formatted }}
                </span>
              </span>
            </b-col>
            <b-col class="d-flex align-items-center">
              <address-map :lat="contractorAddressCenter.lat" :lng="contractorAddressCenter.lng"
                :style="{ width: '100%' }"></address-map>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row>
            <b-col class="d-flex justify-content-center">
              <b-btn variant="success" @click="onAddToSecondmentClick">{{
                $t("Add contractor")
              }}</b-btn>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </div>
    <b-modal ref="addContractorModal" size="xl" hide-footer hide-header>
      <secondment-actor-table ref="contractorTable" :value="localActor" :countries="countries" :title="$t('Contractor')"
        actor-id="contractor_object_id" @input="onSecondmentContractorTableInput">
      </secondment-actor-table>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AddressMap from "@/view/components/AddressMap.vue";
import CompanyService from "@/core/services/company/company.service";
import SecondmentActorTable from "@/view/components/tables/SecondmentActorTable.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";

import { backendErrorSwal } from "@/core/helpers/swal";
import _ from "lodash";
import { mapState } from "vuex";
import { unsavedChangesSwal } from "@/core/helpers/swal";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
export default {
  components: {
    AddressMap,
    SecondmentActorTable,
    CountryFlag
  },

  props: {
    contractor: {
      type: Object,
      default: null,
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localActor: null,
      localContractorExtended: null,
      loading: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    isPhysicalPerson() {
      if (this.localActor.kind == "PhysicalPerson") return true;

      return false;
    },

    localActorService() {
      if (this.isPhysicalPerson) return PhysicalPersonService;

      return CompanyService;
    },

    actorAddressField() {
      if (this.isPhysicalPerson) return "physicalpersonaddress_set";

      return "companyaddress_set";
    },

    actorTelephoneField() {
      if (this.isPhysicalPerson) return "telcontact_set";

      return "telcontactscompany_set";
    },

    actorEmailField() {
      if (this.isPhysicalPerson) return "extraemail_set";

      return "emailcompany_set";
    },

    actorAvatarField() {
      if (this.isPhysicalPerson) return "avatar";

      return "logo";
    },

    contractorAddressCenter() {
      if (!this.contractorAddress) return { lat: null, lng: null };
      return {
        lat: this.contractorAddress.address.latitude,
        lng: this.contractorAddress.address.longitude,
      };
    },

    contractorTelephone() {
      if (!this.localContractorExtended || !this.localContractorExtended[this.actorTelephoneField].length) return "";

      const mainPhone = this.localContractorExtended[this.actorTelephoneField].find(x => x.main === true);

      if (!mainPhone) return "";

      if (!mainPhone && this.localContractorExtended[this.actorTelephoneField].length > 0) {
        return this.localContractorExtended[this.actorTelephoneField][0].number;
      }

      return mainPhone.number;
    },

    contractorMail() {
      if (!this.localContractorExtended || !this.localContractorExtended[this.actorEmailField].length) return "";

      return this.localContractorExtended[this.actorEmailField][0].email;
    },

    contractorAddress() {
      if (!this.localContractorExtended || !this.localContractorExtended[this.actorAddressField].length) return null;

      const mainAddress = this.localContractorExtended[this.actorAddressField].find(x => x.main === true);

      if (!mainAddress && this.localContractorExtended[this.actorAddressField].length > 0) {
        return this.localContractorExtended[this.actorAddressField][0];
      }
      if (!mainAddress) return null;

      return mainAddress;
    },

    contractorAddressRaw() {
      if (this.contractorAddress !== null) {
        return this.contractorAddress.address.formatted;
      }
      return "";
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments")},
      { title: this.$t("Contractor") },
    ]);
    this.resetContractor();
  },

  methods: {
    onSaveClick() {
      this.$emit("update-contractor", this.localActor);
    },

    onResetClick() {
      this.resetContractor();
    },

    async resetContractor() {
      this.localActor = _.cloneDeep(this.contractor);
      await this.loadContractor();
    },

    onAddToSecondmentClick() {
      this.$refs.addContractorModal.show();
    },

    async loadContractor() {
      if (this.localActor) {
        this.loading = true;
        await this.localActorService.getOneOldAddressFormat({
          id: this.localActor.contractor_object_id
        }).then(respsonse => {
          this.localContractorExtended = respsonse.data;
        }).catch(err => {
          console.error('@loadContractor', err.response.data);
          backendErrorSwal(err, err?.response?.data?.detail ?? this.$t("Something went wrong!"));
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    async onSecondmentContractorTableInput(actor) {
      if (!actor) {
        this.localContractorExtended = null;
        this.localActor = null;
        return;
      }

      this.localActor = {
        ...actor,
        id: this.contractor?.id,
        contractor_object_id: actor.id,
        contractor_content_type: actor.content_type,
        kind: actor.kind,
        date_start: this.dateStart,
      };

      this.$refs.addContractorModal.hide();
      await this.loadContractor();
    },

    hasUnsavedChanges() {
      if (this.contractor?.id !== this.localActor?.id) return true;
      if (this.contractor?.contractor !== this.localActor?.contractor) return true;
      if (this.contractor?.notify !== this.localActor?.notify) return true;
      if (this.contractor?.date_start !== this.localActor?.date_start) return true;

      return false;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.hasUnsavedChanges()) {
      next();
      return;
    }

    unsavedChangesSwal().then(result => {
      if (result.isConfirmed) {
        this.$emit("update-contractor", this.localActor);
        next();
      } else if (result.isDenied) {
        next();
      }
    });
  },
};
</script>
