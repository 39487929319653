var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Contractor")))])])]), _c('div', {
    staticClass: "card-body"
  }, [_vm.loading ? _c('b-container', [_vm._v(" " + _vm._s(_vm.$t("loading...")) + " ")]) : [_vm.contractor ? _c('b-container', [_c('b-row', [_c('b-col', [_c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold d-flex justify-content-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center pb-5"
  }, [_c('avatar', {
    attrs: {
      "avatar-image": _vm.contractor.avatar,
      "avatar-text": _vm.contractor.name[0],
      "size": "100px"
    }
  }), _c('span', {
    staticClass: "h2 text-capitalize"
  }, [_vm._v(_vm._s(_vm.contractor.name))])], 1)]), _c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-center pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Allow notifications")) + ":")]), _c('b-form-checkbox', {
    attrs: {
      "disabled": "",
      "switch": "",
      "size": "lg"
    },
    model: {
      value: _vm.contractor.notify,
      callback: function callback($$v) {
        _vm.$set(_vm.contractor, "notify", $$v);
      },
      expression: "contractor.notify"
    }
  })], 1), _vm.contractor.vat ? _c('div', [_c('b-row', {
    staticClass: "font-weight-bold d-flex align-items-center pb-5 ml-5"
  }, [_c('div', {
    staticClass: "pr-3 pb-2 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Vat")) + ":")]), _vm._l(Object.entries(_vm.contractor.vat), function (_ref) {
    var country = _ref[0],
      vat = _ref[1];
    return _c('b-col', {
      key: vat,
      attrs: {
        "cols": "12 pb-2"
      }
    }, [_c('CountryFlag', {
      staticClass: "px-1",
      attrs: {
        "country-iso": country
      }
    }), _vm._v(" " + _vm._s(vat) + " ")], 1);
  })], 2)], 1) : _vm._e(), _c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-baseline pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Address")) + ":")]), _c('p', [_vm._v(_vm._s(_vm.contractorAddressRaw))])])], 1)], 1), _c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-baseline pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_vm.contractor.registered_office ? _c('span', [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Registered office")) + ":")]), _vm._l(_vm.contractor.registered_office, function (office) {
    return _c('span', {
      key: office.address.id
    }, [_vm._v(" " + _vm._s(office.address.formatted) + " ")]);
  })], 2) : _vm._e()]), _c('b-col', {
    staticClass: "d-flex align-items-center"
  }, [_c('address-map', {
    style: {
      width: '100%'
    },
    attrs: {
      "lat": _vm.contractorAddressCenter.lat,
      "lng": _vm.contractorAddressCenter.lng
    }
  })], 1)], 1)], 1) : _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-btn', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.onAddToSecondmentClick
    }
  }, [_vm._v(_vm._s(_vm.$t("Add contractor")))])], 1)], 1)], 1)]], 2), _c('b-modal', {
    ref: "addContractorModal",
    attrs: {
      "size": "xl",
      "hide-footer": "",
      "hide-header": ""
    }
  }, [_c('secondment-actor-table', {
    ref: "contractorTable",
    attrs: {
      "value": _vm.localActor,
      "countries": _vm.countries,
      "title": _vm.$t('Contractor'),
      "actor-id": "contractor_object_id"
    },
    on: {
      "input": _vm.onSecondmentContractorTableInput
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }